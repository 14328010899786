import React from "react"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"

const HowItWorksHomeBanner = ({ cms }) => {
  return (
    <>
      <Box
        className="how-it-works-home-banner"
        component="section"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Container>
          <div className="banner-left">
            <h1>{cms.title}</h1>
            <p>{cms.content}</p>
          </div>
        </Container>
      </Box>
    </>
  )
}

export default HowItWorksHomeBanner
