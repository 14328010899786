import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import CardAdvantages, {
  CardAdvantagesLink,
} from "../../components/cardAdvanteges"

const CarebitsAdvantagesBanner = ({ cms }) => {
  return (
    <Box
      className="carebits-advantages-banner"
      component="section"
      display="flex"
      alignItems="center"
      textAlign="center"
    >
      <Container>
        <h1>{cms.title}</h1>
        <Box
          className="carebits-advantages-banner"
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
        >
          {cms.list.map((element, index) => {
            return <CardAdvantages text={element.content}></CardAdvantages>
          })}
          <CardAdvantagesLink
            text={cms.link_bar.box_title}
            link={cms.link_bar.box_link}
          ></CardAdvantagesLink>
        </Box>
      </Container>
    </Box>
  )
}

export default CarebitsAdvantagesBanner
