import React from "react"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import Fade from "react-reveal/Fade"
import Markdown from "react-markdown"

import hearthWrapper from "../../assets/left-half-hearth.svg"
import bannerLogo from "../../assets/what-carebits-is-banner.png"

const WhatCarebitsIsBanner = ({ cms }) => {
  return (
    <Box
      className="what-carebits-is-banner"
      component="section"
      display="flex"
      alignItems="center"
    >
      <img
        className="hearth-wrapper"
        src={hearthWrapper}
        alt="Ipad with carebits app view"
      ></img>
      <Container>
        <Grid container>
          <Grid item className="banner-left" xs={12} lg={6}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              position="relative"
            >
              <Fade fraction={0.5}>
                <img src={bannerLogo} alt="Ipad with carebits app view"></img>
              </Fade>
            </Box>
          </Grid>
          <Grid item className="banner-right" xs={12} lg={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              position="relative"
            >
              <div>
                <h1>{cms.title}</h1>
                <Markdown allowDangerousHtml>{cms.content}</Markdown>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default WhatCarebitsIsBanner
