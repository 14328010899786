import React from "react"
import Fade from "react-reveal/Fade"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import { CheckCircleFill } from "react-bootstrap-icons"
import bannerLogo from "../../assets/why-trust-us-doctor.png"
const WhyTrustUsBanner = ({ cms }) => {
  return (
    <Box
      className="why-trust-us-banner"
      component="section"
      display="flex"
      alignItems="center"
    >
      <Container>
        <Grid container>
          <Grid item className="banner-left" xs={12} lg={7}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <div>
                <h1>{cms.title}</h1>
                <dl className="why-trust-us-list">
                  {cms.list.map((element, index) => {
                    return (
                      <Fade fraction={0.5} duration={1250}>
                        <dt>
                          <CheckCircleFill></CheckCircleFill>
                          {element.content}
                          {element.sub_content?.map((subelement, index) => (
                            <dd>{subelement.content}</dd>
                          ))}
                        </dt>
                      </Fade>
                    )
                  })}
                </dl>
              </div>
            </Box>
          </Grid>

          <Grid item className="banner-right" xs={12} lg={5}>
            <Box display="flex" alignItems="flex-end" height="100%">
              <img src={bannerLogo} alt="Logo with doctor and kid"></img>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default WhyTrustUsBanner
